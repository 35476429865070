import React, {useState} from "react";
import ReactMarkdown from 'react-markdown'
import {gql, useMutation} from "@apollo/client";

const ADD_CONTACT = gql`
  mutation AddContact($preName: String!, $lastName: String!, $email: String!, $phone: String, $message: String, $tenantId: ID!) {
    createContact(
      input: {
        data: {
          PreName: $preName
          LastName: $lastName
          Email: $email
          Phone: $phone
          Message: $message
          Tenant: $tenantId
        }
      }
    ) {
      contact {
        id
      }
    }
  }
`;


function Contact(prop) {
    const [addContact] = useMutation(ADD_CONTACT);
    const [serverState, setServerState] = useState({
        status: false
    });

    const handleServerResponse = (ok, form) => {
        setServerState({
            status: ok
        });
        if (ok) {
            form.reset();
        }
    };

    let firstName;
    let lastName;
    let email;
    let phone;
    let message;

    let template = prop.template
    let tenantId = prop.tenantId

    return (
        <div className="relative mt-24 sm:mt-32 sm:py-16">
            <div aria-hidden="true" className="hidden sm:block">
                <div className="absolute inset-y-0 left-0 w-1/2 bg-gray-50 rounded-r-3xl"></div>
                <svg className="absolute top-8 left-1/2 -ml-3" width="404" height="392" fill="none"
                     viewBox="0 0 404 392">
                    <defs>
                        <pattern id="8228f071-bcee-4ec8-905a-2a059a2cc4fb" x="0" y="0" width="20" height="20"
                                 patternUnits="userSpaceOnUse">
                            <rect x="0" y="0" width="4" height="4" className="text-gray-200" fill="currentColor"/>
                        </pattern>
                    </defs>
                    <rect width="404" height="392" fill="url(#8228f071-bcee-4ec8-905a-2a059a2cc4fb)"/>
                </svg>
            </div>
            <div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8">
                <div className="relative rounded-2xl bg-white rounded-2xl shadow-xl">
                    <h2 className="sr-only">
                        Kontaktieren Sie uns
                    </h2>

                    <div className="grid grid-cols-1 lg:grid-cols-3">
                        <div
                            className="relative rounded-l-2xl overflow-hidden py-10 px-6 bg-mintGreen sm:px-10 xl:p-12">
                            <h3 className="text-lg font-medium text-white">Kontakt</h3>
                            <div className="mt-6 prose text-base text-white max-w-3xl">
                                <ReactMarkdown>{template.Contact.Description}</ReactMarkdown>
                            </div>
                            <dl className="mt-8 space-y-6">
                                <dt><span className="sr-only">Phone number</span></dt>
                                <dd className="flex text-base text-indigo-50">
                                    <svg className="flex-shrink-0 w-6 h-6 text-white"
                                         xmlns="http://www.w3.org/2000/svg" fill="none"
                                         viewBox="0 0 24 24"
                                         stroke="currentColor"
                                         aria-hidden="true">
                                        <path strokeLinecap="round" strokeLinejoin="round"
                                              strokeWidth="2"
                                              d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"/>
                                    </svg>
                                    <span className="ml-3 text-white">{template.Contact.Phone}</span>
                                </dd>
                                <dt><span className="sr-only">Email</span></dt>
                                <dd className="flex text-base text-indigo-50">
                                    <svg className="flex-shrink-0 w-6 h-6 text-white"
                                         xmlns="http://www.w3.org/2000/svg" fill="none"
                                         viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                        <path strokeLinecap="round" strokeLinejoin="round"
                                              strokeWidth="2"
                                              d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"/>
                                    </svg>
                                    <span className="ml-3 text-white">{template.Contact.Email}</span>
                                </dd>
                            </dl>
                        </div>

                        <div className="py-10 px-6 rounded-r-2xl sm:px-10 lg:col-span-2 xl:p-12">

                            {!serverState.status && (
                                <div>
                                    <h3 className="text-lg font-medium text-gray-900">Senden Sie uns eine Nachricht</h3>
                                    <form
                                        onSubmit={e => {
                                            e.preventDefault();
                                            addContact({
                                                variables: {
                                                    preName: firstName.value,
                                                    lastName: lastName.value,
                                                    email: email.value,
                                                    phone: phone.value,
                                                    message: message.value,
                                                    tenantId: tenantId
                                                }
                                            }).then(() => {
                                                handleServerResponse(true, e.target);
                                            }).catch(err => {
                                                console.error(err)
                                            });
                                        }}
                                        className="mt-6 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8">
                                        <div>
                                            <label htmlFor="first_name"
                                                   className="block text-sm font-medium text-gray-900">
                                                Vorname
                                            </label>
                                            <div className="mt-1">
                                                <input type="text" name="first_name" id="first_name"
                                                       autoComplete="given-name"
                                                       required
                                                       ref={node => {
                                                           firstName = node;
                                                       }}
                                                       className="py-3 px-4 block w-full shadow-sm text-gray-900 focus:ring-mintGreen focus:border-mintGreen border-gray-300 rounded-md"/>
                                            </div>
                                        </div>
                                        <div>
                                            <label htmlFor="last_name"
                                                   className="block text-sm font-medium text-gray-900">
                                                Name
                                            </label>
                                            <div className="mt-1">
                                                <input type="text" name="last_name" id="last_name"
                                                       autoComplete="family-name"
                                                       required
                                                       ref={node => {
                                                           lastName = node;
                                                       }}
                                                       className="py-3 px-4 block w-full shadow-sm text-gray-900 focus:ring-mintGreen focus:border-mintGreen border-gray-300 rounded-md"/>
                                            </div>
                                        </div>
                                        <div>
                                            <label htmlFor="email"
                                                   className="block text-sm font-medium text-gray-900">E-Mail</label>
                                            <div className="mt-1">
                                                <input id="email"
                                                       name="email"
                                                       type="email"
                                                       autoComplete="email"
                                                       required
                                                       ref={node => {
                                                           email = node;
                                                       }}
                                                       className="py-3 px-4 block w-full shadow-sm text-gray-900 focus:ring-mintGreen focus:border-mintGreen border-gray-300 rounded-md"/>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="flex justify-between">
                                                <label htmlFor="phone"
                                                       className="block text-sm font-medium text-gray-900">Telefon</label>
                                                <span id="phone-optional"
                                                      className="text-sm text-gray-500">Optional</span>
                                            </div>
                                            <div className="mt-1">
                                                <input type="text"
                                                       name="phone"
                                                       id="phone"
                                                       autoComplete="tel"
                                                       ref={node => {
                                                           phone = node;
                                                       }}
                                                       className="py-3 px-4 block w-full shadow-sm text-gray-900 focus:ring-mintGreen focus:border-mintGreen border-gray-300 rounded-md"
                                                       aria-describedby="phone-optional"/>
                                            </div>
                                        </div>
                                        <div className="sm:col-span-2">
                                            <div className="flex justify-between">
                                                <label htmlFor="message"
                                                       className="block text-sm font-medium text-gray-900">Nachricht</label>
                                                <span id="message-max" className="text-sm text-gray-500">Max. 500 characters</span>
                                            </div>
                                            <div className="mt-1">
                                                        <textarea id="message"
                                                                  name="message"
                                                                  required
                                                                  rows="4"
                                                                  ref={node => {
                                                                      message = node;
                                                                  }}
                                                                  className="py-3 px-4 block w-full shadow-sm text-gray-900 focus:ring-mintGreen focus:border-mintGreen border-gray-300 rounded-md"
                                                                  aria-describedby="message-max"/>
                                            </div>
                                        </div>
                                        <div className="sm:col-span-2 sm:flex sm:justify-end">
                                            <button type="submit"
                                                    className="mt-2 w-full inline-flex items-center justify-center px-6 py-3 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-inkBlue hover:bg-black focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-inkBlue sm:w-auto">
                                                Absenden
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            )}
                            {serverState.status && (
                                <div className="py-12 px-4">
                                    <h2 className="text-2xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-3xl sm:leading-9">
                                        Vielen Dank für Ihre Mitteilung.
                                    </h2>
                                    <p className="text-center mt-3 text-lg leading-6 text-gray-900">
                                        Wir bearbeiten Ihre Anfrage und melden uns umgehend bei Ihnen zurück.
                                    </p>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Contact;
