import React from "react";

function PartnerLogo(prop) {
    let template = prop.template
    return (
        <div className="mt-32">
            <div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
                <div className="lg:grid lg:grid-cols-2 lg:gap-24 lg:items-center">
                    <div>
                        <h2 className="text-3xl font-extrabold text-inkBlue tracking-tight sm:text-4xl">
                            {template.Partner.Title}
                        </h2>
                        <p className="mt-6 max-w-3xl text-lg leading-7 text-gray-500">
                            {template.Partner.Description}
                        </p>
                    </div>
                    <div className="mt-12 grid grid-cols-2 gap-0.5 md:grid-cols-3 lg:mt-0 lg:grid-cols-2">
                        { template.Partner.Logos.map(logo => (
                                <div key={logo.id}className="col-span-1 flex justify-center py-8 px-8 bg-gray-50">
                                    <img className="max-h-12" src={(process.env.NODE_ENV === "production" ? '' : 'http://localhost:1337') + logo.url}
                                         alt="Logo"/>
                                </div>
                            )
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PartnerLogo;
