import React from "react";
import {Helmet} from "react-helmet";

function CAMwidget(prop) {
    console.log(prop)
    return (
        <div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8 mt-20">
            <link rel="stylesheet" href={process.env.CLICKAND_CDN + "ca-meet-widget.min.css"}/>

            <div id={"clickandmeet"} data-key={prop.tenantKey}/>

            <Helmet>
                <script src={process.env.CLICKAND_CDN + 'ca-meet-widget.min.js'} type="text/javascript" />
            </Helmet>
        </div>
    )
}


export default CAMwidget
