import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import PropTypes from 'prop-types';
import SEO from "../components/seo";
import Contact from "../components/simple/contact";

import Newsletter from "../components/simple/newsletter";
import Hero from "../components/simple/hero";
import TestimonialDescription from "../components/simple/testimonialDescription";
import PartnerLogo from "../components/simple/partnerLogo";
import Footer from "../components/simple/footer";
import CAMwidget from "../components/simple/camwidget";

class SimpleTemplate extends React.Component {
    render() {
        const brand = this.props.data.strapiPage.Brand;
        const seoTitle = this.props.data.strapiPage.SeoTitle;
        const seoKeywords = this.props.data.strapiPage.SeoKeywords;
        const seoDescription = this.props.data.strapiPage.SeoDescription;

        const tenantId = this.props.data.strapiPage.Tenant.id
        const tenantKey = this.props.data.strapiPage.Tenant.Key
        const template =  this.props.data.strapiPage.Template[0]

        return (
            <Layout>
                <SEO
                    keywords={seoKeywords}
                    description={seoDescription}
                    title={seoTitle}
                />
                <div className="bg-white">
                    <main>
                        {/* Hero section */}
                        <Hero template={template}/>

                        {/* Testimonial/stats section */}
                        { (template.Testimonial.Author && template.Testimonial.Description && template.Testimonial.Testimonial && template.Testimonial.Title) && (
                            <TestimonialDescription template={template}/>
                        )}

                        {/* ClickAndMeetWidget */}
                        { template.ClickAndMeet.Active && (
                            <CAMwidget tenantKey={tenantKey}/>
                        )}

                        {/* CTA section */}
                        { (template.CTA.Description && template.CTA.Receiver && template.CTA.Title) && (
                            <Newsletter tenantId={tenantId} template={template} />
                        )}

                        {/* Logo cloud section */}
                        { (template.Partner.Description && template.Partner.Title) && (
                            <PartnerLogo template={template} />
                        )}


                        {/* Contact Form */}
                        <Contact tenantId={tenantId} template={template} />

                    </main>

                    {/* Footer section */}
                    <Footer brand={brand} template={template}/>

                </div>
            </Layout>
        )
    }
}

export default SimpleTemplate

SimpleTemplate.propTypes = {
    data: PropTypes.object.isRequired
};

export const query = graphql`
  query PageTemplate($id: Int!) {
    strapiPage(strapiId: {eq: $id}) {
      Tenant {
        id
        Key
      }
      Brand
      SeoTitle
      SeoKeywords
      SeoDescription
      Template {
        Hero {
          Title
          Description
          ImportantNotice
          ImportantNoticeUrl
          BrandLogo {
            url
          }
          Teaser {
            url
          }
        }
        Testimonial {
          Author
          Description
          Testimonial
          Title
          Facts {
            key
            value
          }
          TestimonialImage {
            url
          }
        }
        CTA {
          Description
          Receiver
          Title
        }
        Partner {
          Description
          Title
          Logos {
            id
            url
          }
        }
        Contact {
          Description
          Email
          Phone
        }
        Footer {
          FacebookUrl
          InstagramUrl
          TwitterUrl
        }
        ClickAndMeet {
          Active
        } 
      }
    }
  }
`
