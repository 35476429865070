import React, {useState} from "react";
import {gql, useMutation} from "@apollo/client";

const ADD_NEWSLETTER = gql`
  mutation AddNewsletter($email: String!, $tenantId: ID!) {
    createNewsletter(input: { data: { Email: $email, Tenant: $tenantId } }) {
      newsletter {
        id
      }
    }
  }
`;

function Newsletter(prop) {
    const [addNewsletter] = useMutation(ADD_NEWSLETTER);
    const [serverState, setServerState] = useState({
        status: false
    });

    const handleServerResponse = (ok, form) => {
        setServerState({
            status: ok
        });
        if (ok) {
            form.reset();
        }
    };

    let email;
    let template = prop.template
    let tenantId = prop.tenantId
    return (
        <div className="pt-8 overflow-hidden sm:pt-12 lg:relative lg:py-24">
            <div className="sm:mx-auto sm:max-w-3xl sm:px-6">
                <div className="py-12 sm:relative sm:mt-12 sm:py-16 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
                    <div className="hidden sm:block">
                        <div
                            className="absolute inset-y-0 left-1/2 w-screen bg-gray-50 rounded-l-3xl lg:left-80 lg:right-0 lg:w-full"/>
                        <svg className="absolute top-8 right-1/2 -mr-3 lg:m-0 lg:left-0" width="404" height="392"
                             fill="none"
                             viewBox="0 0 404 392">
                            <defs>
                                <pattern id="837c3e70-6c3a-44e6-8854-cc48c737b659" x="0" y="0" width="20" height="20"
                                         patternUnits="userSpaceOnUse">
                                    <rect x="0" y="0" width="4" height="4" className="text-gray-200"
                                          fill="currentColor"/>
                                </pattern>
                            </defs>
                            <rect width="404" height="392" fill="url(#837c3e70-6c3a-44e6-8854-cc48c737b659)"/>
                        </svg>
                    </div>
                </div>
            </div>
            <div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8">
                <div
                    className="relative rounded-2xl px-6 py-10 bg-mintGreen overflow-hidden shadow-xl sm:px-12 sm:py-20">
                    <div aria-hidden="true" className="absolute inset-0 -mt-72 sm:-mt-32 md:mt-0">
                        <svg className="absolute inset-0 h-full w-full" preserveAspectRatio="xMidYMid slice"
                             xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 1463 360">
                        </svg>
                    </div>
                    {!serverState.status && (
                        <div className="relative">
                            <div className="sm:text-center">
                                <h2 className="text-3xl font-extrabold text-white tracking-tight sm:text-4xl">
                                    {template.CTA.Title}
                                </h2>
                                <p className="mt-6 mx-auto max-w-2xl text-lg text-white">
                                    {template.CTA.Description}
                                </p>
                            </div>
                            <form onSubmit={e => {
                                e.preventDefault();
                                addNewsletter({variables: {email: email.value, tenantId: tenantId}}).then(() => {
                                    handleServerResponse(true, e.target);
                                }).catch(err => {
                                    console.error(err)
                                });
                            }}
                                  className="mt-12 sm:mx-auto sm:max-w-lg sm:flex">
                                <div className="min-w-0 flex-1">
                                    <label htmlFor="cta_email" className="sr-only">Email address</label>
                                    <input id="email"
                                           name="email"
                                           type="email"
                                           autoComplete="email"
                                           required
                                           ref={node => {
                                               email = node;
                                           }}
                                           className="block w-full border border-transparent rounded-md px-5 py-3 text-base text-gray-900 placeholder-gray-500 shadow-sm focus:outline-none focus:border-transparent focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-white"
                                           placeholder="E-Mail-Adresse eingeben"/>
                                </div>
                                <div className="mt-4 sm:mt-0 sm:ml-3">
                                    <button type="submit"
                                            className="block w-full rounded-md border border-transparent px-5 py-3 bg-inkBlue text-base font-medium text-white shadow hover:bg-black focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-white sm:px-10">Abonnieren
                                    </button>
                                </div>
                            </form>
                        </div>
                    )}
                    {serverState.status && (
                        <div className="py-12 px-4">
                            <h2 className="text-2xl text-center leading-8 font-extrabold tracking-tight text-white sm:text-3xl sm:leading-9">
                                Vielen Dank für Ihre Mitteilung.
                            </h2>
                            <p className="text-center mt-3 text-lg leading-6 text-white">
                                Wir bearbeiten Ihre Anfrage und melden uns umgehend bei Ihnen zurück.
                            </p>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default Newsletter;
